import tokenizer from "../utils/tokenizer";
import {
  firestore,
  auth,
  getDoc,
  setDoc,
  collection,
  doc,
  updateDoc,
} from "../firebase";

const promptBuilder = (messages, systemRole) => {
  /**
   * Todo: Implement the promptBuilder function.
   */
};

/**
 * Function to save the total tokens used by the user on a message
 * @param {*} newMsgToken
 * @returns void
 */
const saveTotalTokens = (newMsgToken) => {
  // get the user's id
  const userId = auth.currentUser.uid;
  // get the date
  const date = new Date().toISOString().split("T")[0];
  // get the document or create it if it doesn't exist
  const userDocRef = doc(firestore, "users", userId);
  // get the document data
  getDoc(userDocRef).then((docSnap) => {
    if (docSnap.exists()) {
      // if the document exists, get the data
      const data = docSnap.data();
      // check if the date is in the data
      if (data[date]) {
        // if the date is in the data, update the document
        updateDoc(userDocRef, {
          [date]: data[date] + newMsgToken,
        });
      } else {
        // if the date is not in the data, add the date and totalTokens
        setDoc(userDocRef, {
          [date]: newMsgToken,
        });
      }
    } else {
      // if the document doesn't exist, create it
      setDoc(userDocRef, {
        [date]: newMsgToken,
      });
    }
  });
};

export default promptBuilder;
